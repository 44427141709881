// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify, type ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { md3 } from 'vuetify/blueprints';

const light: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#006e18',
    secondary: '#006a63',
  },
};

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    primary: '#7ddc77',
    secondary: '#00ded1',
  },
};

export default createVuetify({
  components,
  directives,
  blueprint: md3,
  theme: {
    themes: {
      light,
      dark,
    },
  },
});
