export function cols(size: string) {
  const breakpoint = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
    xl: 3,
    xxl: 2,
  } as { [key: string]: number };
  return breakpoint[size];
}
