import { acceptHMRUpdate, defineStore } from 'pinia';
import type { Recipe, RecipePayload } from '@/service/recipe/types';
import type { Ref } from 'vue';
import type RecipeClient from '@/service/recipe/recipeClient';

export type RecipeStore = typeof useRecipeStore;

export function useRecipeStore(client: RecipeClient) {
  const store = defineStore('recipe', () => {
    const recipes = client.collectionRef();
    return {
      recipes,
      getRecipes(): Ref<Recipe[]> {
        return recipes;
      },
      getRecipe(id: string): Ref<Recipe | undefined> {
        return client.documentRef(id);
      },
      async get(id: string): Promise<Recipe> {
        return client.getOne(id);
      },
      async save(payload: RecipePayload): Promise<void> {
        await client.save(payload);
      },
      async update(payload: RecipePayload): Promise<void> {
        await client.update(payload);
      },
      async remove(id: string): Promise<void> {
        await client.remove(id);
      },
    };
  });

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
  }

  return store();
}
