<template>
  <v-switch
    v-model="theme"
    hide-details
    inset=""
    :true-value="trueValue"
    :false-value="falseValue"
    :aria-label="`Theme: ${theme}`"
  ></v-switch>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useTheme } from 'vuetify';
  import { useHead } from '@vueuse/head';
  import { useSettingStore } from '@/stores/settings';
  import { storeToRefs } from 'pinia';

  export default defineComponent({
    name: 'ThemeSwitch',
    setup() {
      const globalTheme = useTheme();
      const settingStore = useSettingStore();
      const { theme } = storeToRefs(settingStore);

      useHead({
        meta: [
          {
            property: 'theme-color',
            key: 'theme-color',
            name: 'theme-color',
            content: () => {
              return globalTheme.global.current.value.colors.primary;
            },
          },
        ],
      });

      return { theme, trueValue: 'dark', falseValue: 'light' };
    },
  });
</script>

<style scoped></style>
