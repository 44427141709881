import { registerPlugins } from '@/plugins';
import { createApp } from 'vue';
import App from './App.vue';

async function startApplication(): Promise<void> {
  const app = createApp(App);
  await registerPlugins(app);
  app.mount('#app');
}

startApplication();
