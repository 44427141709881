<template>
  <theme-provider>
    <v-app>
      <suspense>
        <router-view />
      </suspense>
    </v-app>
  </theme-provider>
</template>

<script lang="ts">
  import { useHead } from '@vueuse/head';
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import ThemeProvider from '@/components/theme-switch/ThemeProvider.vue';

  export default defineComponent({
    name: 'App',
    components: { ThemeProvider },
    setup() {
      const router = useRouter();
      useHead({
        title: () => {
          return String(router.currentRoute.value.meta['title']);
        },
      });
      return {};
    },
  });
</script>
