<template>
  <v-theme-provider :theme="theme" with-background>
    <slot />
  </v-theme-provider>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useSettingStore } from '@/stores/settings';
  import { storeToRefs } from 'pinia';

  export default defineComponent({
    name: 'ThemeProvider',
    setup() {
      const settingStore = useSettingStore();
      const { theme } = storeToRefs(settingStore);
      return { theme };
    },
  });
</script>
