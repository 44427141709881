<template>
  <v-dialog
    :model-value="open"
    @update:model-value="close"
    transition="slide-x-reverse-transition"
    fullscreen="fullscreen"
    scrim="false"
  >
    <v-card width="100%">
      <v-toolbar color="primary">
        <v-btn icon="mdi-close" dark @click="close" aria-label="close" />
        <v-toolbar-title>Edit {{ form.values.recipe?.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text=""
            @click="emitSubmit"
            :loading="loading"
            :disabled="loading"
          >
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pa-0" style="overflow-y: scroll">
        <recipe-form :form="form" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import RecipeForm from '@/components/recipe/recipe-form/RecipeForm.vue';
  import type { PropType } from 'vue';
  import { defineComponent } from 'vue';
  import {
    type Recipe,
    RecipeFormSchema,
    type RecipePayload,
    RecipeSchema,
  } from '@/service/recipe/types';
  import { useForm } from 'vee-validate';
  import { toTypedSchema } from '@vee-validate/zod';
  import { z } from 'zod';

  function defaultValues(recipe: Recipe | undefined): RecipePayload {
    const { data: defaults } = RecipeFormSchema.extend({
      recipe: RecipeSchema.extend({
        title: z.string().optional(),
        preparationTime: z.number().optional(),
      }),
    }).safeParse({
      recipe,
      images: [],
    });
    return defaults;
  }

  export default defineComponent({
    name: 'RecipeDialog',
    components: {
      RecipeForm,
    },
    props: {
      open: Boolean,
      loading: Boolean,
      recipe: Object as PropType<Recipe>,
    },
    emits: ['submit', 'close'],
    setup(props, { emit }) {
      const form = useForm({
        validationSchema: toTypedSchema(RecipeFormSchema),
        initialValues: defaultValues(props.recipe),
        keepValuesOnUnmount: true,
      });

      const submit = form.handleSubmit(async (recipe) => {
        emit('submit', recipe);
        form.resetForm();
      });

      return {
        form,
        close() {
          form.resetForm();
          emit('close', false);
        },
        async emitSubmit() {
          await submit();
        },
      };
    },
  });
</script>

<style scoped></style>
