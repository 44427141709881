import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSettingStore = defineStore(
  'settings',
  () => {
    const theme = ref<string>('light');
    return { theme };
  },
  {
    persist: {
      storage: localStorage,
      paths: ['theme'],
    },
  },
);
