<template>
  <v-card>
    <v-skeleton-loader
      :loading="!recipe.image"
      type="image"
      :boilerplate="true"
    >
      <v-img
        :lazy-src="recipe.image"
        :src="recipe.image"
        :height="200"
        cover="cover"
      ></v-img>
    </v-skeleton-loader>

    <v-card-title>
      {{ recipe.title }}
    </v-card-title>

    <v-card-subtitle>
      <recipe-rating :recipe="recipe" />
      <p v-if="recipe?.preparationTime">
        {{ recipe.preparationTime }} minutes
        <v-icon icon="mdi-clock-outline" size="small" />
      </p>
    </v-card-subtitle>

    <v-card-text>
      <p>{{ recipe.subTitle }}</p>
    </v-card-text>

    <v-card-actions>
      <v-btn color="secondary" variant="text" :to="recipe.id" aria-label="View">
        View
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { defineComponent } from 'vue';
  import RecipeRating from '@/components/recipe/recipe-rating/RecipeRating.vue';
  import type { Recipe } from '@/service/recipe/types';

  export default defineComponent({
    name: 'RecipeCard',
    components: { RecipeRating },
    props: {
      recipe: { type: Object as PropType<Recipe>, required: true },
    },
  });
</script>
