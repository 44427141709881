import type { User } from '@firebase/auth';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useFirebaseAuth } from 'vuefire';
import type { AuthIdentifier } from '@/service/types';

export const useAuthStore = defineStore(
  'auth',
  () => {
    const auth = useFirebaseAuth();
    const router = useRouter();

    const authenticated = ref(false);
    const user = ref<User>();
    return {
      authenticated,
      user,
      setUser(value: User): void {
        authenticated.value = true;
        user.value = value;
      },
      getUser(): User {
        if (user.value) {
          return user.value;
        }
        throw new Error('No user');
      },
      getIdentifier(): AuthIdentifier {
        if (user.value) {
          return { uid: user.value?.uid };
        }
        throw new Error('No user');
      },
      async logout(): Promise<void> {
        await auth?.signOut();
        await router.push({ name: 'login' });
        authenticated.value = false;
        user.value = undefined;
      },
    };
  },
  {
    persist: {
      storage: localStorage,
      paths: ['theme', 'authenticated', 'user'],
    },
  },
);
