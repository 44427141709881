<template>
  <v-dialog
    :model-value="shareDialog"
    :fullscreen="true"
    transition="slide-x-reverse-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn :icon="true" dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Sharing</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn variant="text" @click="share(shared)"> Save</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list lines="two" subheader>
        <v-list-subheader>Sharing</v-list-subheader>
        <v-list-item
          title="Shared"
          subtitle="When enabling sharing, your recipe will become visible to anyone with the link!"
        >
          <template v-slot:prepend>
            <v-checkbox v-model="shared" />
          </template>
        </v-list-item>
        <v-list-item v-if="shared">
          <v-text-field
            variant="outlined"
            :model-value="shareUrl"
            :readonly="true"
            prepend-icon="mdi-share"
            append-icon="mdi-content-copy"
            @click:append="copyToClipboard"
          />
        </v-list-item>
      </v-list>
    </v-card>
    <v-snackbar :model-value="copiedToClipboard">
      Copied to clipboard!
    </v-snackbar>
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent, type PropType, ref } from 'vue';
  import type { Recipe } from '@/service/recipe/types.ts';

  export default defineComponent({
    name: 'ShareDialog',
    props: {
      recipe: {
        type: Object as PropType<Recipe>,
        required: true,
      },
      share: {
        type: Function as PropType<(share: boolean) => void>,
        required: true,
      },
      shareDialog: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['close'],
    setup(props) {
      const shared = ref(props.recipe.shared);
      const copiedToClipboard = ref(false);
      const shareUrl = `${location.origin}/share/${props.recipe.id}`;

      return {
        shared,
        shareUrl,
        copiedToClipboard,
        copyToClipboard() {
          copiedToClipboard.value = true;
          navigator.clipboard.writeText(shareUrl);
        },
      };
    },
  });
</script>
