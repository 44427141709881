<template>
  <nav-bar color="primary" :active="mdAndUp" :title="title" />
  <nav-drawer
    app
    :key="profile"
    :profile="profile"
    :active="mdAndDown"
    :items="items"
  />
  <v-main>
    <offline-notification />
    <slot />
  </v-main>
  <nav-bottom :items="items" :active="smAndDown" />
</template>

<script lang="ts">
  import NavBar from '@/components/infrastructure/nav-bar/NavBar.vue';
  import NavDrawer from '@/components/infrastructure/nav-drawer/NavDrawer.vue';
  import { useNavigationStore } from '@/stores/navigation';
  import { defineComponent, onMounted, type PropType, watch } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  import { useDisplay } from 'vuetify';
  import ProfileClient from '@/service/profile/profileClient';
  import { useProfileStore } from '@/stores/profile';
  import NavBottom from '@/components/infrastructure/nav-bottom/NavBottom.vue';
  import OfflineNotification from '@/components/offline-notification/OfflineNotification.vue';

  export default defineComponent({
    name: 'MainView',
    components: {
      OfflineNotification,
      NavBottom,
      NavDrawer,
      NavBar,
    },
    props: {
      client: {
        type: Object as PropType<ProfileClient>,
        required: true,
      },
    },
    setup(props) {
      const authStore = useAuthStore();
      const profileStore = useProfileStore(props.client);
      const profile = profileStore.getProfile();
      const navStore = useNavigationStore();
      const { mdAndUp, mdAndDown, smAndDown } = useDisplay();

      onMounted(() => {
        navStore.set(mdAndUp.value);
      });

      watch(
        () => mdAndUp.value,
        (value) => {
          navStore.set(value);
        },
      );

      return {
        mdAndUp,
        mdAndDown,
        smAndDown,
        profile,
        title: 'Dinnr Time',
        items: [
          {
            icon: 'mdi-food',
            title: 'Recipe',
            value: 'recipe',
          },
          {
            icon: 'mdi-cart',
            title: 'Groceries',
            value: 'groceries',
          },
          {
            icon: 'mdi-account',
            title: 'Profile',
            value: 'profile',
          },
        ],
        logout: async function () {
          await authStore.logout();
        },
      };
    },
  });
</script>
