<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-card v-bind="props" :border="0" :rounded="false" flat="">
      <div class="text-center">
        <v-avatar
          class="mouse-over ma-4"
          size="150"
          @click="$refs.input.click()"
          color="surface-variant"
          v-if="avatar || !photo"
        >
          <v-icon icon="mdi-image-plus" size="100" v-if="!photo"></v-icon>
          <v-img cover="" :src="photo" v-if="photo"></v-img>
          <v-overlay
            :model-value="isHovering || $vuetify.display.platform.touch"
            :scrim="hoverAttr.overlayColor"
            contained=""
          >
            <v-btn
              icon="mdi-pencil"
              size="150"
              variant="text"
              :color="hoverAttr.btnColor"
            />
          </v-overlay>
        </v-avatar>
        <div
          v-if="!avatar && photo"
          class="mouse-over"
          @click="$refs.input.click()"
        >
          <v-img
            cover="cover"
            :lazy-src="photo"
            :src="photo"
            v-if="photo"
            :height="height"
          ></v-img>
          <v-overlay
            :model-value="isHovering || $vuetify.display.platform.touch"
            :scrim="hoverAttr.overlayColor"
            contained=""
            width="100%"
            height="100%"
          >
            <v-btn
              height="100%"
              width="100%"
              variant="text"
              :color="hoverAttr.btnColor"
            >
              <v-icon icon="mdi-pencil" />
            </v-btn>
          </v-overlay>
        </div>
      </div>
      <v-file-input
        prepend-icon="mdi-camera"
        ref="input"
        label="Image"
        variant="underlined"
        v-show="false"
        v-model="fileInput"
      />
    </v-card>
  </v-hover>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { defineComponent, ref, watch } from 'vue';
  import { useTheme } from 'vuetify';

  export default defineComponent({
    name: 'ImageInput',
    props: {
      original: { type: String, default: '' },
      modelValue: Object as PropType<File[]>,
      height: { type: Number, default: 250 },
      avatar: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const fileInput = ref([]);
      const photo = ref<string>(props.original);
      const globalTheme = useTheme();
      const theme = globalTheme.global.current.value;
      const hoverAttr = ref({
        overlayColor: theme.colors.background,
        btnColor: theme.colors['on-background'],
      });

      watch(fileInput, (files) => {
        if (files.length) {
          photo.value = URL.createObjectURL(files[0]);
          emit('update:modelValue', files);
        }
      });
      return { photo, fileInput, hoverAttr };
    },
  });
</script>

<style scoped>
  .mouse-over {
    cursor: pointer;
  }
</style>
