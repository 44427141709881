import type { App, Plugin } from 'vue';
import { VueFire, VueFireAuth } from 'vuefire';
import type { FirebaseApp } from 'firebase/app';
import { firebase } from '@/config/Firebase';

export default function createVuefire(firebaseApp: FirebaseApp): Plugin {
  return {
    install(app: App) {
      app.use(VueFire, {
        firebaseApp,
        modules: [VueFireAuth()],
      });
    },
  };
}

export const vuefire = createVuefire(firebase.app);
