import { createPinia } from 'pinia';
import { markRaw } from 'vue';
import router from '@/router';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

export default pinia;
