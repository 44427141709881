<template>
  <v-container class="fill-height" fluid>
    <v-row class="fill-height">
      <v-col align-self="center" class="text-center">
        <v-progress-circular
          indeterminate=""
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'loading-circle',
  };
</script>

<style scoped></style>
