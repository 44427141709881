import type { FirebaseConfig } from '@/config/Firebase';
import {
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';

export const productionConfig: FirebaseConfig = {
  applicationConfig: {
    apiKey: 'AIzaSyCzVMuzgq90kw-HK82rmhbmywvobkADjD4',
    authDomain: 'dinner-time-3eea9.firebaseapp.com',
    projectId: 'dinner-time-3eea9',
    storageBucket: 'dinner-time-3eea9.appspot.com',
    messagingSenderId: '613946445738',
    appId: '1:613946445738:web:8ab46e764dfbf13450a5d3',
    measurementId: 'G-DNKKNF2KHP',
  },
  firestoreConfig: {
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
    }),
  },
};
