<template>
  <v-container>
    <div ref="container"></div>
  </v-container>
</template>

<script lang="ts">
  import 'firebaseui/dist/firebaseui.css';
  import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
  import { type Auth, EmailAuthProvider } from 'firebase/auth';
  import { useFirebaseAuth } from 'vuefire';
  import { useRouter } from 'vue-router';
  import * as firebaseui from 'firebaseui';
  import AuthUI = firebaseui.auth.AuthUI;

  function setupAuthUI(
    selector: string | Element,
    auth: Auth | null,
    signInSuccessUrl: string,
    signInSuccessWithAuthResult: (authResult, redirectUrl?: string) => boolean,
  ): AuthUI {
    const ui = AuthUI.getInstance() || new AuthUI(auth);
    ui.start(selector, {
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        },
      ],
      signInSuccessUrl,
      callbacks: {
        signInSuccessWithAuthResult,
      },
    });
    return ui;
  }

  export default defineComponent({
    name: 'LoginView',
    setup() {
      const container = ref();
      const ui = ref<AuthUI>();
      const auth = useFirebaseAuth();
      const router = useRouter();
      const redirectUrl = String(
        router.currentRoute.value.query['redirect'] || '/',
      );

      onMounted(() => {
        ui.value = setupAuthUI(container.value, auth, redirectUrl, () => {
          router.push(redirectUrl);
          return false;
        });
      });

      onUnmounted(() => {
        ui.value?.reset();
      });

      return { container };
    },
  });
</script>
