import type { Firestore } from 'firebase/firestore';
import type { FirebaseStorage } from 'firebase/storage';
import type { Ref, UnwrapRef } from 'vue';

export interface Groups {
  users: string[];
}

export class EntityNotFound extends Error {}

export type { Firestore, FirebaseStorage };

export interface ClientInterface<T, P> {
  collectionRef(): Ref<T[]> | Ref<UnwrapRef<T[]>>;

  documentRef(id: string): Ref<T | undefined> | Ref<UnwrapRef<T> | undefined>;

  get(): Promise<T[]>;

  getOne(id: string): Promise<T>;

  save(payload: P): Promise<void>;

  update(payload: P): Promise<void>;

  remove(id: string): Promise<void>;
}

export interface AuthIdentifier {
  uid: string;
  groups?: string[];
}
