<template>
  <profile-view :client="client" />
</template>

<script lang="ts">
  import ProfileClient from '@/service/profile/profileClient';
  import ProfileView from '@/views/profile/ProfileView.vue';
  import { defineComponent } from 'vue';
  import { getCurrentUser, useFirebaseStorage, useFirestore } from 'vuefire';
  import type { User } from '@firebase/auth';

  export default defineComponent({
    name: 'ProfileViewRoute',
    components: { ProfileView },
    async setup() {
      const user = (await getCurrentUser()) as User;
      const profileClient = new ProfileClient(
        user,
        useFirestore(),
        useFirebaseStorage(),
      );
      return { client: profileClient };
    },
  });
</script>

<style scoped></style>
