<template>
  <v-toolbar color="background">
    <v-btn
      icon="mdi-arrow-left"
      @click="() => $router.back()"
      aria-label="back"
    />
    <v-spacer></v-spacer>
    <v-btn icon="mdi-check-all" @click="checkAll" aria-label="clear all" />
  </v-toolbar>
  <v-container v-if="groceries">
    <v-card>
      <v-list>
        <v-list-subheader>Groceries</v-list-subheader>

        <v-list-item
          v-for="(grocery, index) in groceries.list"
          :key="index"
          density="compact"
        >
          <template v-slot:prepend>
            <v-list-item-action start="">
              <v-checkbox-btn
                :name="`item-${index + 1}`"
                v-model="grocery.done"
              ></v-checkbox-btn>
            </v-list-item-action>
          </template>

          <v-list-item-title>
            <v-text-field
              :aria-label="`grocery item ${index + 1}`"
              :placeholder="`grocery item ${index + 1}`"
              v-model="grocery.text"
              variant="plain"
              density="compact"
              hide-details="auto"
              class="dense"
              autofocus=""
            />
          </v-list-item-title>
          <template v-slot:append>
            <v-list-item-action end="">
              <v-icon
                icon="mdi-close"
                aria-label="remove"
                @click="remove(index)"
              />
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-list-item @click="add">
          <template v-slot:prepend>
            <v-list-item-action start="">
              <v-icon icon="mdi-plus" aria-label="add"></v-icon>
            </v-list-item-action>
          </template>
          <v-list-item-title>Add grocery item</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useGroceryStore } from '@/stores/groceries';
  import { storeToRefs } from 'pinia';

  export default defineComponent({
    setup() {
      const groceryStore = useGroceryStore();
      const { groceries } = storeToRefs(groceryStore);

      return {
        groceries,
        add() {
          groceries.value.list.push({ text: '', done: false });
        },
        remove(index: number) {
          groceries.value.list.splice(index, 1);
        },
        checkAll() {
          groceries.value.list.splice(0, groceries.value.list.length);
        },
      };
    },
  });
</script>

<style>
  .dense input {
    padding-top: 4px !important;
  }
</style>
