<template>
  <main-view :client="profileClient">
    <suspense-view />
  </main-view>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import MainView from '@/views/main/MainView.vue';
  import SuspenseView from '@/components/load-application/SuspenseView.vue';
  import ProfileClient from '@/service/profile/profileClient';
  import UserService from '@/service/user/UserService.ts';
  import { getCurrentUser, useFirebaseStorage, useFirestore } from 'vuefire';

  export default defineComponent({
    name: 'AuthenticatedRoute',
    components: { SuspenseView, MainView },
    async setup() {
      const user = await getCurrentUser();
      if (!user) {
        throw new Error('Login Required!');
      }
      const userService = new UserService(useFirestore(), user);
      const profileClient = new ProfileClient(
        user,
        useFirestore(),
        useFirebaseStorage(),
      );
      await userService.init();

      return { profileClient };
    },
  });
</script>
