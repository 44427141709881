<template>
  <detail-view :id="id" :client="client" />
</template>

<script>
  import DetailView from '@/views/recipe/detail/DetailView.vue';
  import RecipeClient from '@/service/recipe/recipeClient';
  import { useAuthStore } from '@/stores/auth';

  export default {
    name: 'DetailViewRoute',
    components: { DetailView },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    async setup() {
      const authStore = useAuthStore();
      const client = await RecipeClient.setup(authStore.getIdentifier());
      return { client };
    },
  };
</script>
