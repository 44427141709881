import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useNavigationStore = defineStore('navigation', () => {
  const drawer = ref(false);

  return {
    drawer,
    toggle() {
      drawer.value = !drawer.value;
    },
    set(value: boolean) {
      drawer.value = value;
    },
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavigationStore, import.meta.hot));
}
