import type { AuthIdentifier, FirebaseStorage } from '@/service/types';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export interface File {
  path: string;
  data: Blob | Uint8Array | ArrayBuffer;
  extension: string;
}

export interface UploadedFile {
  path: string;
}

export interface UploadClientInterface {
  upload(file: File): Promise<UploadedFile>;
}

export default class UploadClient implements UploadClientInterface {
  constructor(
    private readonly identifier: AuthIdentifier,
    private readonly storage: FirebaseStorage,
  ) {}

  async upload({ path, data, extension }: File): Promise<UploadedFile> {
    const storageRef = ref(
      this.storage,
      `${this.identifier.uid}/${path}.${extension}`,
    );
    await uploadBytes(storageRef, data, {
      cacheControl: 'private, max-age=31536000',
    });
    return {
      path: await getDownloadURL(storageRef),
    };
  }
}
