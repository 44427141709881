import type { User } from '@firebase/auth';
import type { Profile } from '@/service/profile/types';

export function userToProfile(user: User, groups: string[]): Profile {
  return {
    displayName: user.displayName || '',
    email: user.email || '',
    phoneNumber: user.phoneNumber || '',
    photoUrl: user.photoURL || '',
    providerId: user.providerId,
    uid: user.uid,
    id: '',
    groups: [...new Set([user.uid, ...groups])],
  };
}
