import { acceptHMRUpdate, defineStore } from 'pinia';
import type { GroceryList } from '@/service/groceries/types';
import { ref } from 'vue';

export function useGroceryStore() {
  const store = defineStore(
    'groceries',
    () => {
      const groceries = ref<GroceryList>({ list: [] });

      return { groceries };
    },
    {
      persist: {
        storage: localStorage,
        paths: ['groceries'],
      },
    },
  );

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
  }

  return store();
}
