import { z } from 'zod';
import { v4 } from 'uuid';

export const units = [
  'Milligram',
  'Gram',
  'Kilogram',
  'Millilitre',
  'Deciliter',
  'Liter',
  'Teaspoon(s)',
  'Tablespoon(s)',
  'Cup(s)',
] as const;

const StepSchema = z
  .object({
    text: z.string().min(1),
  })
  .required();

const IngredientSchema = z
  .object({
    text: z.string().min(1).max(256),
    amount: z.number().positive(),
    unit: z.enum([...units, '']).default(''),
  })
  .required();

export const RecipeSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => v4()),
  title: z.string().min(1).max(256).default(''),
  subTitle: z.string().max(256).default(''),
  preparationTime: z.number().positive().default(0),
  rating: z.record(z.number()).default({}),
  steps: z.array(StepSchema).max(512).default([]),
  ingredients: z.array(IngredientSchema).default([]),
  image: z.string().default(''),
  shared: z.boolean().default(false),
});

export const RecipeFormSchema = z.object({
  recipe: RecipeSchema,
  images: z.array(z.custom<File>()).default([]),
});

export type Recipe = z.infer<typeof RecipeSchema>;
export type Step = z.infer<typeof StepSchema>;
export type Ingredient = z.infer<typeof IngredientSchema>;
export type RecipePayload = z.infer<typeof RecipeFormSchema>;
