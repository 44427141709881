import type { User } from '@firebase/auth';
import Repository, { type RepositoryInterface } from '@/service/repository.ts';
import type { Profile } from '@/service/profile/types.ts';
import { userToProfile } from '@/service/profile/utils.ts';
import type { Firestore } from 'firebase/firestore';
import { EntityNotFound, type Groups } from '@/service/types.ts';

export default class UserService {
  private readonly users: RepositoryInterface<Profile>;
  private readonly groups: RepositoryInterface<Groups>;

  constructor(
    readonly store: Firestore,
    readonly user: User,
  ) {
    this.users = new Repository<Profile>('users', store, {
      uid: user.uid,
      groups: [],
    });
    this.groups = new Repository<Groups>('groups', store, {
      uid: user.uid,
      groups: [],
    });
  }

  public async init(): Promise<void> {
    await this.upsertUser();
    await this.upsertGroups();
  }

  private async upsertUser(): Promise<void> {
    const userProfile = userToProfile(this.user, [this.user.uid]);
    try {
      const profile = await this.users.getOne(this.user.uid);
      await this.users.update({
        ...userProfile,
        ...profile,
        id: this.user.uid,
      });
    } catch (e) {
      if (e instanceof EntityNotFound) {
        await this.users.save({
          ...userProfile,
          id: this.user.uid,
        });
      }
    }
  }

  private async upsertGroups(): Promise<void> {
    try {
      await this.groups.getOne(this.user.uid);
    } catch (e) {
      if (e instanceof EntityNotFound) {
        await this.groups.save({
          id: this.user.uid,
          users: [this.user.uid],
        });
      }
    }
  }
}
