<script lang="ts">
  import { defineComponent, ref } from 'vue';

  export default defineComponent({
    name: 'OfflineNotification',
    setup() {
      const snackbar = ref(false);
      const sticky = ref(true);
      const online = ref(navigator.onLine);

      function updateOnline({ type }: Event): void {
        online.value = type === 'online';
      }

      window.addEventListener('online', updateOnline);
      window.addEventListener('offline', updateOnline);

      return {
        snackbar,
        sticky,
        online,
        text: 'Your network is unavailable.',
      };
    },
  });
</script>
<template>
  <v-app-bar scroll-behavior="elevate" v-if="!online">
    <v-banner icon="mdi-cloud-off-outline" color="error" :sticky="sticky">
      <template v-slot:text>
        <strong>{{ text }}</strong>
      </template>
    </v-banner>
  </v-app-bar>
</template>

<style scoped></style>
