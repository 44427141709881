<template>
  <v-container>
    <v-row>
      <v-toolbar color="background">
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="outlined" @click="dialog = true">
          <v-icon icon="mdi-plus" />
          Add a recipe
        </v-btn>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col
        :cols="cols($vuetify.display.name)"
        v-for="(recipe, index) in recipes"
        :key="index"
      >
        <recipe-card :recipe="recipe" />
      </v-col>
      <v-col v-if="!recipes">
        <v-card elevation="0">
          <v-card-title class="text-center">
            <v-icon icon="mdi-emoticon-sad-outline" size="100"></v-icon>
            <p>No recipes found!</p>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <recipe-dialog
      :loading="loading"
      :open="dialog"
      @submit="submit"
      @close="dialog = false"
    />
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, type PropType, ref } from 'vue';
  import RecipeCard from '@/components/recipe/recipe-card/RecipeCard.vue';
  import { useRecipeStore } from '@/stores/recipe';
  import RecipeDialog from '@/components/recipe/recipe-dialog/RecipeDialog.vue';
  import { cols } from '@/service/vuetifyHelper';
  import type { RecipePayload } from '@/service/recipe/types';
  import RecipeClient from '@/service/recipe/recipeClient';

  export default defineComponent({
    name: 'RecipeView',
    components: {
      RecipeDialog,
      RecipeCard,
    },
    props: {
      client: {
        type: Object as PropType<RecipeClient>,
        required: true,
      },
    },
    setup(props) {
      const dialog = ref(false);
      const loading = ref(false);
      const store = useRecipeStore(props.client);

      return {
        recipes: store.getRecipes(),
        dialog,
        loading,
        cols,
        async submit(recipe: RecipePayload) {
          loading.value = true;
          await store.save(recipe);
          loading.value = false;
          dialog.value = false;
        },
      };
    },
  });
</script>
