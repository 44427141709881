<template>
  <v-toolbar color="background">
    <v-btn
      icon="mdi-arrow-left"
      @click="$router.push({ name: 'recipe' })"
    ></v-btn>
    <v-spacer></v-spacer>
    <v-btn icon="mdi-pencil" @click="editDialog = true"></v-btn>
  </v-toolbar>
  <v-container v-if="profile">
    <v-row>
      <v-col>
        <profile-card :profile="profile" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-list>
            <v-list-subheader>Settings</v-list-subheader>
            <v-list-item
              title="Dark theme"
              subtitle="Enable or disable dark theme"
            >
              <template v-slot:prepend>
                <v-avatar>
                  <v-icon icon="mdi-theme-light-dark" />
                </v-avatar>
              </template>
              <template v-slot:append>
                <theme-switch />
              </template>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-list>
            <v-list-subheader>Danger</v-list-subheader>
            <v-list-item
              title="Logout"
              subtitle="Switch to another user"
              @click="logout"
            >
              <template v-slot:prepend>
                <v-avatar>
                  <v-icon icon="mdi-logout" />
                </v-avatar>
              </template>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <profile-dialog
      :loading="loading"
      :profile="profile"
      :open="editDialog"
      @submit="update"
      @close="editDialog = false"
    />
  </v-container>
</template>

<script lang="ts">
  import ProfileCard from '@/components/profile/profile-card/ProfileCard.vue';
  import ProfileDialog from '@/components/profile/profile-dialog/ProfileDialog.vue';
  import ThemeSwitch from '@/components/theme-switch/ThemeSwitch.vue';
  import { cols } from '@/service/vuetifyHelper';
  import { defineComponent, type PropType, ref } from 'vue';
  import type { ProfilePayload } from '@/service/profile/types';
  import type ProfileClient from '@/service/profile/profileClient';
  import { useProfileStore } from '@/stores/profile';
  import { useAuthStore } from '@/stores/auth';

  export default defineComponent({
    name: 'ProfileView',
    components: {
      ProfileDialog,
      ThemeSwitch,
      ProfileCard,
    },
    props: {
      client: {
        type: Object as PropType<ProfileClient>,
        required: true,
      },
    },
    setup(props) {
      const authStore = useAuthStore();
      const profileStore = useProfileStore(props.client);
      const profile = profileStore.getProfile();
      const editDialog = ref(false);
      const loading = ref(false);

      return {
        cols,
        editDialog,
        loading,
        profile,
        async update(payload: ProfilePayload) {
          loading.value = true;
          await profileStore.update(payload);
          loading.value = false;
          editDialog.value = false;
        },
        logout() {
          authStore.logout();
        },
      };
    },
  });
</script>

<style scoped></style>
