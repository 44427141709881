<template>
  <v-dialog
    :model-value="open"
    transition="slide-x-reverse-transition"
    fullscreen="fullscreen"
  >
    <v-toolbar color="primary">
      <v-btn icon="mdi-close" dark @click="emitClose" />
      <v-toolbar-title>Edit {{ displayName.value.value }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          text=""
          @click="emitSubmit"
          :loading="loading"
          :disabled="loading"
        >
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-form>
          <image-input
            :original="photoUrl.value.value"
            :avatar="true"
            :height="200"
            v-model="photo.value.value"
          />
          <v-text-field
            name="displayName"
            prepend-icon="mdi-account"
            label="Display name"
            variant="underlined"
            v-model="displayName.value.value"
            :error-messages="displayName.errors.value"
          />
          <v-text-field
            name="phoneNumber"
            prepend-icon="mdi-phone"
            label="Phone number"
            variant="underlined"
            v-model="phoneNumber.value.value"
            :error-messages="phoneNumber.errors.value"
          />
          <v-text-field
            name="password"
            prepend-icon="mdi-lock"
            label="Password"
            variant="underlined"
            :type="passwordField.type"
            :append-icon="passwordField.icon"
            :on-click:append="changePasswordField"
            v-model="password.value.value"
            :error-messages="password.errors.value"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import ImageInput from '@/components/inputs/image-input/ImageInput.vue';
  import type { Profile, ProfilePayload } from '@/service/profile/types';
  import { ProfilePayloadSchema } from '@/service/profile/types';
  import { toTypedSchema } from '@vee-validate/zod';
  import { useField, useForm } from 'vee-validate';
  import { defineComponent, type PropType, ref } from 'vue';

  export default defineComponent({
    name: 'ProfileDialog',
    components: { ImageInput },
    props: {
      open: Boolean,
      loading: Boolean,
      profile: Object as PropType<Profile>,
    },
    emits: ['close', 'submit'],
    setup(props, { emit }) {
      const passwordField = ref({ type: 'password', icon: 'mdi-eye-off' });
      const { handleSubmit } = useForm({
        validationSchema: toTypedSchema(ProfilePayloadSchema),
        initialValues: {
          profile: props.profile,
          photo: [],
        },
      });
      const photoUrl = useField<string>('profile.photoUrl');
      const displayName = useField<string>('profile.displayName');
      const phoneNumber = useField<string>('profile.phoneNumber');
      const password = useField<string>('password');
      const photo = useField<File[]>('photo');

      const onSubmit = handleSubmit((profile: ProfilePayload) => {
        emit('submit', profile);
      });

      return {
        photoUrl,
        displayName,
        phoneNumber,
        password,
        photo,
        passwordField,
        changePasswordField() {
          if (passwordField.value.type === 'password') {
            passwordField.value = { type: 'text', icon: 'mdi-eye' };
          } else {
            passwordField.value = { type: 'password', icon: 'mdi-eye-off' };
          }
        },
        emitClose() {
          emit('close', false);
        },
        async emitSubmit() {
          await onSubmit();
        },
      };
    },
  });
</script>
