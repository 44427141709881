import {
  collection,
  deleteDoc,
  doc,
  type DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  query,
  Query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { type AuthIdentifier, EntityNotFound } from '@/service/types';

export interface RepositoryInterface<T> {
  collectionRef(): Query;

  documentRef(id: string): DocumentReference;

  get(): Promise<T[]>;

  getOne(id: string): Promise<T>;

  save(docData: T & { id: string }): Promise<void>;

  update(docData: T & { id: string }): Promise<void>;

  remove(id: string): Promise<void>;
}

export default class Repository<T extends DocumentData = DocumentData>
  implements RepositoryInterface<T>
{
  private readonly timeout: number = 100;

  constructor(
    readonly entity: string,
    readonly firestore: Firestore,
    readonly identifier: AuthIdentifier,
  ) {}

  collectionRef(): Query {
    return query(
      collection(this.firestore, this.entity),
      where('user', 'in', this.identifier.groups),
    );
  }

  documentRef(id: string): DocumentReference {
    return doc(this.firestore, this.entity, id);
  }

  async get(): Promise<T[]> {
    const snapshot = await getDocs(this.collectionRef());
    return snapshot.docs.map((doc): T => doc.data() as T);
  }

  async getOne(id: string): Promise<T> {
    try {
      const snapshot = await getDoc(this.documentRef(id));
      if (snapshot.exists()) {
        return snapshot.data() as T;
      }
    } catch (e) {
      // console.error('Unexpected repository error!', e);
    }
    throw new EntityNotFound(`No ${this.entity} found with id: ${id}`);
  }

  async save(docData: T & { id: string }): Promise<void> {
    await Promise.race([
      setDoc(doc(this.firestore, this.entity, docData.id), {
        ...docData,
        user: this.identifier.uid,
      }),
      new Promise((resolve) => setTimeout(resolve, this.timeout)),
    ]);
  }

  async update(docData: T & { id: string }): Promise<void> {
    await Promise.race([
      updateDoc(doc(this.firestore, this.entity, docData.id), {
        ...docData,
        user: this.identifier.uid,
      }),
      new Promise((resolve) => setTimeout(resolve, this.timeout)),
    ]);
  }

  async remove(id: string): Promise<void> {
    await Promise.race([
      deleteDoc(doc(this.firestore, this.entity, id)),
      new Promise((resolve) => setTimeout(resolve, this.timeout)),
    ]);
  }
}
