<template>
  <recipe-view :client="client" />
</template>

<script lang="ts">
  import RecipeView from '@/views/recipe/RecipeView.vue';
  import { useAuthStore } from '@/stores/auth';
  import RecipeClient from '@/service/recipe/recipeClient';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'RecipeViewRoute',
    components: { RecipeView },
    async setup() {
      const authStore = useAuthStore();
      const client = await RecipeClient.setup(authStore.getIdentifier());
      return { client };
    },
  });
</script>

<style scoped></style>
