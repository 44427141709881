<template>
  <v-card>
    <v-card-text class="text-center">
      <v-avatar size="150">
        <v-icon
          icon="mdi-account-circle"
          size="150"
          v-if="!profile?.photoUrl"
        ></v-icon>
        <v-img
          :cover="true"
          :src="profile.photoUrl || ''"
          v-if="profile?.photoUrl"
        ></v-img>
      </v-avatar>
    </v-card-text>
    <v-card-title class="text-center">
      {{ profile.displayName }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-list>
        <v-list-item>
          <template v-slot:prepend>
            <v-icon icon="mdi-email"></v-icon>
          </template>
          {{ profile.email }}
        </v-list-item>
        <v-list-item>
          <template v-slot:prepend>
            <v-icon icon="mdi-phone"></v-icon>
          </template>
          {{ profile.phoneNumber || '...' }}
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import type { Profile } from '@/service/profile/types';
  import { defineComponent, type PropType } from 'vue';

  export default defineComponent({
    name: 'ProfileCard',
    props: {
      profile: Object as PropType<Profile>,
    },
  });
</script>

<style scoped></style>
