import type { FirebaseApp, FirebaseOptions } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import type { Auth } from 'firebase/auth';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import {
  connectFirestoreEmulator,
  Firestore,
  type FirestoreSettings,
  initializeFirestore,
} from 'firebase/firestore';
import type { FirebaseStorage } from 'firebase/storage';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { productionConfig } from '@/config/production';

export interface FirebaseConfig {
  applicationConfig: FirebaseOptions;
  firestoreConfig: FirestoreSettings;
}

interface Endpoints {
  authEndpoint: string;
  firestoreEndpoint: string;
  storageEndpoint: string;
}

export class Firebase {
  private readonly _app: FirebaseApp;
  private readonly _auth: Auth;
  private readonly _firestore: Firestore;
  private readonly _firebaseStorage: FirebaseStorage;

  constructor(
    private readonly config: FirebaseConfig,
    private readonly name?: string,
  ) {
    this._app = initializeApp(this.config.applicationConfig, this.name);
    this._auth = getAuth(this._app);
    this._firestore = initializeFirestore(
      this._app,
      this.config.firestoreConfig,
    );
    this._firebaseStorage = getStorage(this._app);
  }

  public useEmulator(endpoints?: Endpoints): void {
    if (endpoints?.authEndpoint) {
      connectAuthEmulator(this._auth, endpoints.authEndpoint, {
        disableWarnings: true,
      });
    }
    if (endpoints?.firestoreEndpoint) {
      connectFirestoreEmulator(
        this._firestore,
        endpoints.firestoreEndpoint,
        8080,
      );
    }
    if (endpoints?.storageEndpoint) {
      connectStorageEmulator(
        this._firebaseStorage,
        endpoints.storageEndpoint,
        9199,
      );
    }
  }

  get app(): FirebaseApp {
    return this._app;
  }

  get auth(): Auth {
    return this._auth;
  }

  get firestore(): Firestore {
    return this._firestore;
  }

  get storage(): FirebaseStorage {
    return this._firebaseStorage;
  }
}

export const firebase = new Firebase(productionConfig);
if (import.meta.env.DEV) {
  firebase.useEmulator({
    authEndpoint: 'https://auth.dinnr-time.internal:443',
    firestoreEndpoint: 'localhost',
    storageEndpoint: 'localhost',
  });
}
