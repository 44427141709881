<template>
  <v-container v-if="!recipe">
    <v-alert
      type="error"
      icon="mdi-alert-circle-outline"
      title="Not Found!"
      text="Oh oh, it seems like this is not an existing recipe!"
    />
  </v-container>
  <v-container v-if="recipe">
    <v-card>
      <v-skeleton-loader
        :loading="!recipe.image"
        type="image"
        :boilerplate="true"
      >
        <v-img
          :lazy-src="recipe.image"
          :src="recipe.image"
          cover="cover"
          height="200px"
        ></v-img>
      </v-skeleton-loader>

      <template v-slot:append>
        <v-icon icon="mdi-share-variant" />
      </template>

      <v-card-title>
        {{ recipe?.title }}
      </v-card-title>

      <v-card-subtitle v-if="recipe.preparationTime">
        {{ recipe.preparationTime }} minutes
        <v-icon icon="mdi-clock-outline" />
      </v-card-subtitle>

      <v-card-subtitle>
        <recipe-rating :recipe="recipe" @update-rating="() => null" />
      </v-card-subtitle>

      <v-card-text>{{ recipe.subTitle }}</v-card-text>
      <v-divider />
      <v-card-title>How to make:</v-card-title>
      <v-card-text>
        <v-card
          elevation="0"
          v-for="(step, index) in recipe.steps"
          :key="index"
        >
          <v-card-subtitle>Step {{ index }}</v-card-subtitle>
          <v-card-text>
            {{ step.text }}
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-title>Ingredients:</v-card-title>
      <v-card-text>
        <v-list lines="two">
          <v-list-item
            v-for="(ingredient, index) in recipe.ingredients"
            :key="index"
            :title="ingredient.text"
            :subtitle="`${ingredient.amount || ''} ${ingredient.unit || ''}`"
          />
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, type PropType, ref } from 'vue';
  import RecipeRating from '@/components/recipe/recipe-rating/RecipeRating.vue';
  import { useRecipeStore } from '@/stores/recipe';
  import { useRouter } from 'vue-router';
  import RecipeClient from '@/service/recipe/recipeClient';

  export default defineComponent({
    name: 'DetailView',
    components: { RecipeRating },
    props: {
      id: {
        type: String,
        required: true,
      },
      client: {
        type: Object as PropType<RecipeClient>,
        required: true,
      },
    },
    setup(props) {
      const router = useRouter();
      const store = useRecipeStore(props.client);
      const recipe = store.getRecipe(props.id);
      const loading = ref(false);

      return {
        recipe,
        loading,
        async back() {
          await router.push({ name: 'recipe' });
        },
      };
    },
  });
</script>

<style scoped></style>
