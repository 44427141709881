import type { Profile, ProfilePayload } from '@/service/profile/types';
import type { RepositoryInterface } from '@/service/repository';
import Repository from '@/service/repository';
import type { UploadClientInterface, UploadedFile } from '@/service/upload';
import UploadClient from '@/service/upload';
import type { User } from '@firebase/auth';
import { updatePassword, updateProfile } from 'firebase/auth';
import type { Firestore } from 'firebase/firestore';
import {
  type DocumentData,
  QueryDocumentSnapshot,
  type SnapshotOptions,
} from 'firebase/firestore';
import type { FirebaseStorage } from 'firebase/storage';
import type { AuthIdentifier, ClientInterface, Groups } from '@/service/types';
import type { Ref } from 'vue';
import {
  getCurrentUser,
  useCollection,
  useDocument,
  useFirebaseStorage,
  useFirestore,
} from 'vuefire';

const converter = {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options?: SnapshotOptions,
  ): Profile {
    console.log(snapshot.id);
    return {
      id: snapshot.id,
      ...snapshot.data(options),
    } as Profile;
  },
  toFirestore(profile: Profile): DocumentData {
    return profile;
  },
};

export default class ProfileClient
  implements ClientInterface<Profile, ProfilePayload>
{
  private readonly profiles: RepositoryInterface<Profile>;
  private readonly groups: RepositoryInterface<Groups>;
  private readonly uploadClient: UploadClientInterface;

  constructor(
    private readonly user: User,
    store: Firestore,
    storage: FirebaseStorage,
  ) {
    const identifier: AuthIdentifier = { uid: user.uid, groups: [] };
    this.profiles = new Repository<Profile>('users', store, identifier);
    this.groups = new Repository<Groups>('groups', store, identifier);
    this.uploadClient = new UploadClient(identifier, storage);
  }

  collectionRef(): Ref<Profile[]> {
    return useCollection(
      this.profiles.collectionRef().withConverter(converter),
    );
  }

  documentRef(id: string): Ref<Profile | undefined> {
    return useDocument(this.profiles.documentRef(id).withConverter(converter));
  }

  async get(): Promise<Profile[]> {
    return this.profiles.get();
  }

  async getOne(id: string): Promise<Profile> {
    const groups = await this.groups.getOne(id);
    const profile = await this.profiles.getOne(id);
    return { ...profile, groups: groups.users };
  }

  async save({ profile, photo }: ProfilePayload): Promise<void> {
    const groups = await this.groups.getOne(profile.uid);
    const { path } = await this.uploadImage(profile, photo);
    await this.profiles.save({
      ...profile,
      photoUrl: path,
      id: profile.uid,
      groups: groups.users,
    });
  }

  async update({ profile, photo, password }: ProfilePayload): Promise<void> {
    const groups = await this.groups.getOne(profile.uid);
    const { path } = await this.uploadImage(profile, photo);
    await updateProfile(this.user, {
      displayName: profile.displayName,
      photoURL: path,
    });
    if (password) {
      await updatePassword(this.user, password);
    }
    await this.profiles.update({
      ...profile,
      photoUrl: path,
      id: profile.uid,
      groups: groups.users,
    });
  }

  async remove(id: string): Promise<void> {
    return this.profiles.remove(id);
  }

  private async uploadImage(
    profile: Profile,
    photo: File[],
  ): Promise<UploadedFile> {
    if (photo.length) {
      return await this.uploadClient.upload({
        path: `profile/${profile.uid}`,
        data: photo[0],
        extension: 'png',
      });
    }
    return { path: profile.photoUrl };
  }
}
