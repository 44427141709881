import { z } from 'zod';

const UserInfo = z.object({
  displayName: z.string().max(256),
  email: z.string().email(),
  phoneNumber: z.string().max(15),
  photoUrl: z.string(),
  providerId: z.string(),
  uid: z.string(),
});

const Profile = UserInfo.extend({
  id: z.string().optional(),
  groups: z.array(z.string()),
});

export const ProfilePayloadSchema = z.object({
  profile: Profile,
  password: z.string().optional(),
  photo: z.array(z.custom<File>()),
});

export type Profile = z.infer<typeof Profile>;
export type ProfilePayload = z.infer<typeof ProfilePayloadSchema>;
