<template>
  <router-view v-slot="{ Component }">
    <template v-if="Component">
      <Suspense>
        <component :is="Component" />
        <template #fallback>
          <loading-circle />
        </template>
      </Suspense>
    </template>
  </router-view>
</template>

<script lang="ts">
  import LoadingCircle from '@/components/load-application/LoadingCircle.vue';

  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SuspenseView',
    components: { LoadingCircle },
  });
</script>

<style scoped></style>
