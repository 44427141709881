import meta from '@/plugins/meta';
import pinia from '@/plugins/pinia';
import router, { routes } from '@/router';
import type { App } from 'vue';
import { loadFonts } from './webfontloader';
import vuetify from './vuetify';
import { vuefire } from '@/plugins/vuefire';
import { createRouter, createWebHistory } from 'vue-router';

export async function registerPlugins(app: App) {
  await loadFonts();
  app.use(router).use(vuetify).use(pinia).use(meta).use(vuefire);
}

export function registerPluginsStorybook(app: App) {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
  });
  app.use(router).use(vuetify).use(pinia).use(meta).use(vuefire);
}
