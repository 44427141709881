<template>
  <v-app-bar scroll-behavior="hide">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="toggle" v-if="active"></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title>{{ title }}</v-app-bar-title>
  </v-app-bar>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useNavigationStore } from '@/stores/navigation';
  import { storeToRefs } from 'pinia';

  export default defineComponent({
    name: 'NavBar',
    props: {
      active: Boolean,
      title: String,
    },
    setup() {
      const navStore = useNavigationStore();
      const { drawer } = storeToRefs(navStore);
      const { toggle } = navStore;

      return { toggle, drawer };
    },
  });
</script>
