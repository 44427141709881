import ProfileClient from '@/service/profile/profileClient';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { type Ref, ref } from 'vue';
import type { Profile, ProfilePayload } from '@/service/profile/types';
import { useAuthStore } from '@/stores/auth';
import { userToProfile } from '@/service/profile/utils';

export function useProfileStore(client: ProfileClient) {
  const store = defineStore('profile', () => {
    const authStore = useAuthStore();
    const profile = ref<Profile>();
    const theme = ref('light');
    return {
      theme,
      profile,
      getProfile(): Ref<Profile | undefined> {
        profile.value = userToProfile(authStore.getUser(), []);
        return profile;
      },
      async update(payload: ProfilePayload): Promise<void> {
        await client.update(payload);
        profile.value = userToProfile(authStore.getUser(), []);
      },
    };
  });

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
  }

  return store();
}
