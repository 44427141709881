<template>
  <v-card variant="flat">
    <span :class="{ 'pa-4': !image.value.value && !images }">
      <image-input
        :original="image.value.value"
        v-model="images.value.value"
        :height="200"
        :avatar="false"
      />
    </span>
    <v-card-text>
      <v-text-field
        autofocus=""
        name="title"
        label="Title"
        variant="underlined"
        v-model="title.value.value"
        :error-messages="title.errors.value"
      />
      <v-text-field
        name="subTitle"
        label="Description"
        variant="underlined"
        v-model="subTitle.value.value"
        :error-messages="subTitle.errors.value || ''"
      />
      <v-text-field
        name="preparationTime"
        label="Preparation time"
        type="number"
        variant="underlined"
        suffix="minutes"
        v-model="preparationTime.value.value"
        :error-messages="preparationTime.errors.value || ''"
        @update:model-value="
          preparationTime.value.value = Number(
            String(preparationTime.value.value),
          )
        "
      />
      <v-row v-for="({ value, key }, index) in steps.fields.value" :key="key">
        <v-col>
          <strong>Step {{ index + 1 }}</strong>
          <v-text-field
            autofocus=""
            :name="`recipe.steps[${key}].text`"
            :label="`Step ${index + 1}`"
            variant="underlined"
            v-model="value.text"
            append-icon="mdi-minus"
            @click:append="steps.remove(index)"
            :error-messages="
              form.errorBag.value[`recipe.steps[${key}].text`] || ''
            "
          />
        </v-col>
      </v-row>
      <v-row justify="end" class="pa-4">
        <v-btn
          color="secondary"
          varient="outlined"
          prepend-icon="mdi-plus"
          @click="steps.push({ text: '' })"
        >
          Add Step
        </v-btn>
      </v-row>
      <v-row
        v-for="({ key, value }, index) in ingredients.fields.value"
        :key="key"
      >
        <v-col cols="12">
          <strong>Ingredient {{ index + 1 }}</strong>
          <v-text-field
            autofocus="autofocus"
            :name="`recipe.ingredients[${key}].text`"
            :label="`Ingredient ${index + 1}`"
            variant="underlined"
            v-model="value.text"
            append-icon="mdi-minus"
            @click:append="ingredients.remove(index)"
            :error-messages="
              form.errorBag.value[`recipe.ingredients[${key}].text`] || ''
            "
          />
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-text-field
                :name="`recipe.ingredients[${key}].amount`"
                type="number"
                :label="`Amount ${index + 1}`"
                variant="underlined"
                v-model.number="value.amount"
                :error-messages="
                  form.errorBag.value[`recipe.ingredients[${key}].amount`] || ''
                "
              />
            </v-col>
            <v-col>
              <v-select
                :title="`Open Unit ${index + 1}`"
                :name="`recipe.ingredients[${key}].unit`"
                type="number"
                :label="`Unit ${index + 1}`"
                :aria-label="`Unit ${index + 1}`"
                variant="underlined"
                :items="units"
                v-model="value.unit"
                :error-messages="
                  form.errorBag.value[`recipe.ingredients[${key}].unit`] || ''
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="end" class="pa-4">
        <v-btn
          color="secondary"
          varient="outlined"
          prepend-icon="mdi-plus"
          @click="ingredients.push({ text: '', amount: 0, unit: '' })"
        >
          Add Ingredient
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import type { Ingredient, Step } from '@/service/recipe/types';
  import { units } from '@/service/recipe/types';
  import { defineComponent, type PropType } from 'vue';
  import ImageInput from '@/components/inputs/image-input/ImageInput.vue';
  import { type FormContext, useField, useFieldArray } from 'vee-validate';

  export default defineComponent({
    name: 'RecipeForm',
    components: { ImageInput },
    props: {
      form: {
        type: Object as PropType<FormContext>,
        required: true,
      },
    },
    setup() {
      const title = useField<string>('recipe.title');
      const subTitle = useField<string>('recipe.subTitle');
      const preparationTime = useField<number>('recipe.preparationTime');
      const steps = useFieldArray<Step>('recipe.steps');
      const ingredients = useFieldArray<Ingredient>('recipe.ingredients');
      const image = useField<string>('recipe.image');
      const images = useField<File[]>('images');

      return {
        title,
        subTitle,
        preparationTime,
        steps,
        ingredients,
        image,
        images,
        units,
      };
    },
  });
</script>

<style scoped></style>
