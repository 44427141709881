<template>
  <v-bottom-navigation :active="active" :grow="true" class="fixed">
    <v-btn
      v-for="item in items"
      :key="item.value"
      :to="{ name: item.value }"
      :active="isActive(item.value)"
    >
      <v-icon>{{ item.icon }}</v-icon>
      <span>{{ item.title }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts">
  import { defineComponent, type PropType } from 'vue';
  import type { MenuItem } from '@/components/infrastructure/nav-drawer/NavDrawer.vue';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'NavBottom',
    props: {
      active: Boolean,
      items: Object as PropType<MenuItem[]>,
    },
    setup() {
      const router = useRouter();
      return {
        isActive(name: string): boolean {
          return router.currentRoute.value.matched.some(
            (route) => route.name === name,
          );
        },
      };
    },
  });
</script>

<style scoped>
  .fixed {
    position: fixed !important;
  }
</style>
