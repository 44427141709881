<template>
  <v-toolbar color="background">
    <v-btn icon="mdi-arrow-left" @click="back"></v-btn>
    <v-spacer></v-spacer>
    <v-btn
      icon="mdi-share-variant"
      @click="shareDialog = true"
      v-if="recipe"
    ></v-btn>
    <v-btn icon="mdi-delete" @click="deleteDialog = true" v-if="recipe"></v-btn>
    <v-btn icon="mdi-pencil" @click="editDialog = true" v-if="recipe"></v-btn>
  </v-toolbar>
  <v-container v-if="!recipe">
    <v-alert
      type="error"
      icon="mdi-alert-circle-outline"
      title="Not Found!"
      text="Oh oh, it seems like this is not an existing recipe!"
    />
  </v-container>
  <v-container v-if="recipe">
    <v-card>
      <v-skeleton-loader
        :loading="!recipe.image"
        type="image"
        :boilerplate="true"
      >
        <v-img
          :lazy-src="recipe.image"
          :src="recipe.image"
          cover="cover"
          height="200px"
        ></v-img>
      </v-skeleton-loader>

      <v-card-title>{{ recipe?.title }}</v-card-title>

      <v-card-subtitle v-if="recipe.preparationTime">
        {{ recipe.preparationTime }} minutes
        <v-icon icon="mdi-clock-outline" />
      </v-card-subtitle>

      <v-card-subtitle>
        <recipe-rating :recipe="recipe" @update-rating="submit" />
      </v-card-subtitle>

      <v-card-text>{{ recipe.subTitle }}</v-card-text>
      <v-divider />
      <v-card-title>How to make:</v-card-title>
      <v-card-text>
        <v-card
          elevation="0"
          v-for="(step, index) in recipe.steps"
          :key="index"
        >
          <v-card-subtitle>Step {{ index }}</v-card-subtitle>
          <v-card-text>
            {{ step.text }}
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-title>Ingredients:</v-card-title>
      <v-card-text>
        <v-list lines="two">
          <v-list-item
            v-for="(ingredient, index) in recipe.ingredients"
            :key="index"
            :title="ingredient.text"
            :subtitle="`${ingredient.amount || ''} ${ingredient.unit || ''}`"
          />
        </v-list>
      </v-card-text>
    </v-card>
    <delete-dialog
      :open="deleteDialog"
      @close="deleteDialog = false"
      @remove="remove"
    />
    <recipe-dialog
      v-if="recipe"
      :open="editDialog"
      :recipe="recipe"
      :loading="loading"
      @submit="submit"
      @close="editDialog = false"
    />
    <ShareDialog
      v-if="recipe"
      :recipe="recipe"
      :share="share"
      :share-dialog="shareDialog"
      @close="shareDialog = false"
    />
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, type PropType, ref } from 'vue';
  import RecipeDialog from '@/components/recipe/recipe-dialog/RecipeDialog.vue';
  import DeleteDialog from '@/components/delete-dialog/delete-dialog.vue';
  import RecipeRating from '@/components/recipe/recipe-rating/RecipeRating.vue';
  import type { RecipePayload } from '@/service/recipe/types';
  import { useRecipeStore } from '@/stores/recipe';
  import { useRouter } from 'vue-router';
  import RecipeClient from '@/service/recipe/recipeClient';
  import ShareDialog from '@/components/recipe/share-dialog/ShareDialog.vue';

  export default defineComponent({
    name: 'DetailView',
    components: { ShareDialog, DeleteDialog, RecipeDialog, RecipeRating },
    props: {
      id: {
        type: String,
        required: true,
      },
      client: {
        type: Object as PropType<RecipeClient>,
        required: true,
      },
    },
    setup(props) {
      const router = useRouter();
      const store = useRecipeStore(props.client);
      const recipe = store.getRecipe(props.id);
      const loading = ref(false);
      const editDialog = ref(false);
      const deleteDialog = ref(false);
      const shareDialog = ref(false);

      return {
        recipe,
        loading,
        editDialog,
        deleteDialog,
        shareDialog,
        async share(shared: boolean) {
          loading.value = true;
          await store.update({
            recipe: { ...recipe.value!, shared },
            images: [],
          });
          loading.value = false;
          shareDialog.value = false;
        },
        async submit(payload: RecipePayload) {
          loading.value = true;
          await store.update(payload);
          loading.value = false;
          editDialog.value = false;
        },
        async remove() {
          await store.remove(props.id);
          await router.push({ name: 'recipe' });
        },
        async back() {
          await router.push({ name: 'recipe' });
        },
      };
    },
  });
</script>

<style scoped></style>
