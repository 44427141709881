<template>
  <share-view :id="id" :client="client" />
</template>

<script>
  import RecipeClient from '@/service/recipe/recipeClient';
  import { useAuthStore } from '@/stores/auth';
  import ShareView from '@/views/recipe/sharing/ShareView.vue';

  export default {
    name: 'ShareViewRoute',
    components: { ShareView },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    async setup() {
      const authStore = useAuthStore();
      const client = await RecipeClient.setup(authStore.getIdentifier());
      return { client };
    },
  };
</script>
