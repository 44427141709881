<template>
  <v-rating
    color="yellow"
    v-model="rating"
    size="20"
    density="compact"
    half-increments
    @update:modelValue="update"
  />
  ({{ rating }})
</template>

<script lang="ts">
  import { defineComponent, type PropType, ref } from 'vue';
  import type { Recipe } from '@/service/recipe/types';
  import { useAuthStore } from '@/stores/auth';

  function calcRating(rating: number[] = []): number {
    return rating.reduce((a, b) => a + b, 0) / rating.length;
  }

  export default defineComponent({
    name: 'RecipeRating',
    props: {
      recipe: Object as PropType<Recipe>,
    },
    emits: ['updateRating'],
    setup(props, { emit }) {
      const authStore = useAuthStore();
      const user = authStore.getUser();
      const calc = calcRating(Object.values(props.recipe?.rating || {}));
      return {
        rating: ref(calc || 0),
        update(rating) {
          emit('updateRating', {
            recipe: {
              ...props.recipe,
              rating: {
                ...props.recipe?.rating,
                [user.uid]: rating,
              },
            },
            images: [],
          });
        },
      };
    },
  });
</script>

<style scoped></style>
