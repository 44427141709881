<template>
  <v-navigation-drawer
    :permanent="active"
    :model-value="drawer"
    @update:modelValue="set"
  >
    <v-list :nav="true" v-if="profile">
      <v-list-item :nav="true" :title="profile.displayName">
        <template v-slot:prepend>
          <v-avatar>
            <v-img
              :src="profile.photoUrl"
              :cover="true"
              v-if="profile.photoUrl"
            />
            <v-icon icon="mdi-account-circle" v-if="!profile.photoUrl" />
          </v-avatar>
        </template>
        <template v-slot:append>
          <v-btn
            size="small"
            variant="text"
            icon="mdi-cog"
            :to="{ name: 'profile' }"
          ></v-btn>
        </template>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list :nav="true" density="compact">
      <v-list-item
        v-for="({ icon, title, value }, index) in items"
        :key="index"
        :prepend-icon="icon"
        :title="title"
        :value="value"
        :to="{ name: value }"
        :active="isActive(value)"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { defineComponent, type PropType } from 'vue';
  import { useNavigationStore } from '@/stores/navigation';
  import { storeToRefs } from 'pinia';
  import { useRouter } from 'vue-router';
  import type { Profile } from '@/service/profile/types';

  export type MenuItem = {
    icon: string;
    title: string;
    value: string;
  };

  export default defineComponent({
    name: 'NavDrawer',
    props: {
      active: Boolean,
      profile: Object as PropType<Profile>,
      items: Object as PropType<MenuItem[]>,
    },
    emits: ['logout'],
    setup() {
      const navStore = useNavigationStore();
      const router = useRouter();
      const { drawer } = storeToRefs<typeof navStore>(navStore);
      const { set } = navStore;

      return {
        set,
        drawer,
        isActive(name: string): boolean {
          return router.currentRoute.value.matched.some(
            (route) => route.name === name,
          );
        },
      };
    },
  });
</script>
