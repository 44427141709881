import DetailViewRoute from '@/views/recipe/detail/DetailViewRoute.vue';
import LoginView from '@/views/login/LoginView.vue';
import {
  type RouteLocationNormalized,
  type RouteLocationRaw,
  type RouteRecordRaw,
  RouterView,
} from 'vue-router';
import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
} from 'vue-router';
import RecipeViewRoute from '@/views/recipe/RecipeViewRoute.vue';
import GroceriesView from '@/views/groceries/GroceriesView.vue';
import { getCurrentUser } from 'vuefire';
import { useAuthStore } from '@/stores/auth';
import { type User } from '@firebase/auth';
import ProfileViewRoute from '@/views/profile/ProfileViewRoute.vue';
import AuthenticatedRoute from '@/views/main/AuthenticatedRoute.vue';
import ShareViewRoute from '@/views/recipe/sharing/ShareViewRoute.vue';

type ScrollStore = {
  [key: string]: number;
};

type ScrollPosition = {
  behavior?: ScrollOptions['behavior'];
  left?: number;
  top?: number;
};

const saved: ScrollStore = {};

function saveScrollPosition(route: RouteLocationNormalized) {
  saved[route.fullPath] = document.documentElement.scrollTop;
}

function getScrollPosition(route: RouteLocationNormalized): ScrollPosition {
  if (saved[route.fullPath]) {
    return { top: saved[route.fullPath], behavior: 'smooth' };
  }
  return { top: 0, behavior: 'smooth' };
}

async function waitForCurrentUser(
  to: RouteLocationNormalized,
): RouteLocationRaw {
  if (to.meta.requiresAuth) {
    const user: User | null | undefined = await getCurrentUser();
    if (user) {
      const authStore = useAuthStore();
      authStore.setUser(user);
    } else {
      return { path: '/login', query: { redirect: to.fullPath } };
    }
  }
  return null;
}

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    meta: {
      requiresAuth: true,
    },
    component: AuthenticatedRoute,
    children: [
      {
        path: '/',
        name: 'recipe',
        component: RouterView,
        redirect: { name: 'list' },
        meta: { title: 'Recipes' },
        children: [
          {
            path: '/',
            name: 'list',
            component: RecipeViewRoute,
          },
          {
            path: '/:id',
            name: 'detail',
            component: DetailViewRoute,
            props(route: RouteLocationNormalized) {
              return { ...route.params };
            },
          },
          {
            path: '/share/:id',
            name: 'share',
            component: ShareViewRoute,
            props(route: RouteLocationNormalized) {
              return { ...route.params };
            },
          },
        ],
      },
      {
        path: '/profile',
        name: 'profile',
        meta: { title: 'Profile' },
        component: ProfileViewRoute,
      },
      {
        path: '/groceries',
        name: 'groceries',
        meta: { title: 'Groceries' },
        component: GroceriesView,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: 'Login' },
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (to: RouteLocationNormalized) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getScrollPosition(to));
      }, 250);
    });
  },
  routes,
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    saveScrollPosition(from);
    next(await waitForCurrentUser(to));
  },
);

export default router;
