import type { RepositoryInterface } from '@/service/repository';
import Repository from '@/service/repository';
import type {
  AuthIdentifier,
  ClientInterface,
  FirebaseStorage,
  Firestore,
  Groups,
} from '@/service/types';
import type { UploadClientInterface, UploadedFile } from '@/service/upload';
import UploadClient from '@/service/upload';
import type { Recipe, RecipePayload } from '@/service/recipe/types';
import type {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import {
  type _RefFirestore,
  useCollection,
  useDocument,
  useFirebaseStorage,
  useFirestore,
} from 'vuefire';

const converter = {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options?: SnapshotOptions,
  ): Recipe {
    return {
      id: snapshot.id,
      ...snapshot.data(options),
    } as Recipe;
  },
  toFirestore(recipe: Recipe): DocumentData {
    return recipe;
  },
};

export default class RecipeClient
  implements ClientInterface<Recipe, RecipePayload>
{
  private readonly recipes: RepositoryInterface<Recipe>;
  private readonly uploadClient: UploadClientInterface;

  constructor(
    identifier: AuthIdentifier,
    store: Firestore,
    storage: FirebaseStorage,
  ) {
    this.recipes = new Repository<Recipe>('recipes', store, identifier);
    this.uploadClient = new UploadClient(identifier, storage);
  }

  static async setup(identifier: AuthIdentifier): Promise<RecipeClient> {
    const firestore = useFirestore();
    const firebaseStorage = useFirebaseStorage();
    const groups = new Repository<Groups>('groups', firestore, identifier);
    const { users } = await groups.getOne(identifier.uid);
    return new RecipeClient(
      { ...identifier, groups: users },
      firestore,
      firebaseStorage,
    );
  }

  collectionRef(): _RefFirestore<Recipe[]> {
    return useCollection(
      this.recipes.collectionRef().withConverter(converter),
      { ssrKey: 'recipe' },
    );
  }

  documentRef(id: string): _RefFirestore<Recipe | undefined> {
    return useDocument(this.recipes.documentRef(id).withConverter(converter));
  }

  async get(): Promise<Recipe[]> {
    return this.recipes.get();
  }

  async getOne(id: string): Promise<Recipe> {
    return this.recipes.getOne(id);
  }

  async save(payload: RecipePayload): Promise<void> {
    const image = await this.uploadImage(payload);
    await this.recipes.save({
      ...payload.recipe,
      image: image.path,
    });
  }

  async update(payload: RecipePayload): Promise<void> {
    const { path } = await this.uploadImage(payload);
    await this.recipes.update({
      ...payload.recipe,
      image: path,
    });
  }

  async remove(id: string): Promise<void> {
    await this.recipes.remove(id);
  }

  private async uploadImage(payload: RecipePayload): Promise<UploadedFile> {
    if (payload.images.length) {
      return await this.uploadClient.upload({
        path: `recipes/${payload.recipe.id}`,
        data: payload.images[0],
        extension: 'png',
      });
    }
    return { path: payload.recipe.image };
  }
}
