<template>
  <div class="text-center">
    <v-dialog :model-value="open" @update:model-value="close">
      <v-card>
        <v-card-text>
          Do you really want to delete this item? This is an irreversible
          action!
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" block="" @click="remove"> Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'delete-dialog',
    props: {
      open: Boolean,
      callback: Function,
    },
    emits: ['remove', 'close'],
    setup(props, { emit }) {
      return {
        close() {
          emit('close');
        },
        remove() {
          emit('remove');
        },
      };
    },
  });
</script>

<style scoped></style>
